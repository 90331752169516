import React, { useEffect, useState } from 'react';
import './App.css';
const xs = ['C','D♭','D','E♭','E','F','G♭','G','A♭','A','B♭','B'];
const ys = ['M','m','7','M7','m7','dim','6','aug','sus4','-7-5','add9'];

const M = [0, 4, 7];
const m = [0, 3, 7];
const seven = [0, 4, 7, 10];
const M7 = [0, 4, 7, 11];
const m7 = [0, 3, 7, 10];
const dim = [0, 3, 6];
const six = [0, 4, 7, 9];
const aug = [0, 4, 8];
const sus4 = [0, 5, 7];
const m7b5 = [0, 3, 6, 10];
const add9 = [0, 2, 4, 7];

function App() {
  const [notes, setNotes] = useState(Array.from({ length: 12 }, (_, i) => ({ [i]: false })).reduce((acc, obj) => ({ ...acc, ...obj }), {}));
  const [goal, setGoal] = useState("CM");

  useEffect(() => {
    navigator.requestMIDIAccess()
      .then((midiAccess) => {
        const inputs = midiAccess.inputs.values();
        for (let input = inputs.next(); input && !input.done; input = inputs.next()) {
          input.value.onmidimessage = (event) => {
            setNotes((prev) => ({
              ...prev,
              [event.data[1] % 12]: (event.data[0] & (1 << 4)) > 0,
            }));};
        }
      })
      .catch((error) => {
        console.error('MIDI access denied:', error);
      });
  }, []);

  const toOctave = () => {
    const octave = new Array(12).fill(false);
    Object.keys(notes).forEach((key) => {
      octave[(key as unknown as number)] = (notes as any)[key];
    });
    return octave;
  }

  const chord = () => {
    const octave = toOctave();

    let notes: number[] = [];
    for (let i = 0; i < octave.length; i++) {
      if (octave[i]) {
        notes.push(i);
      }
    }
    
    let result = "";

    for (const note of notes) {
    const n =
      note === 0 ? 'C' :
      note === 1 ? 'D♭' :
      note === 2 ? 'D' :
      note === 3 ? 'E♭' :
      note === 4 ? 'E' :
      note === 5 ? 'F' :
      note === 6 ? 'G♭' :
      note === 7 ? 'G' :
      note === 8 ? 'A♭' :
      note === 9 ? 'A' :
      note === 10 ? 'B♭' :
      note === 11 ? 'B' :
      'hmm';

      const countNotes = (notes: boolean[]) => {
        let total = 0;
        Object.values(notes).forEach((n) => {
          if (n) {
            total++;
          }
        });
        return total;
      }

      const matches = (chord: number[]) => {
        for (const n of chord) {
          if (!(octave[(note + n) % 12])) {
            return false;
          }
        }
        return countNotes(octave) === chord.length;
      }

      if (matches(M)) {
        result += n + 'M ';
      }
      if (matches(m)) {
        result += n + 'm ';
      }
      if (matches(seven)) {
        result += n + '7 ';
      }
      if (matches(M7)) {
        result += n + 'M7 ';
      }
      if (matches(m7)) {
        result += n + 'm7 ';
      }
      if (matches(dim)) {
        result += n + 'dim ';
      }
      if (matches(six)) {
        result += n + '6 ';
      }
      if (matches(aug)) {
        result += n + 'aug ';
      }
      if (matches(sus4)) {
        result += n + 'sus4 ';
      }
      if (matches(m7b5)) {
        result += n + '-7-5 ';
      }
      if (matches(add9)) {
        result += n + 'add9 ';
      }
    }

    if (result.includes(goal)) {
      console.log('yay');
      const x = xs[Math.floor(Math.random() * xs.length)];
      const y = ys[Math.floor(Math.random() * ys.length)];
      setGoal(x + y);
    }
    return result;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Playing:</h1>
        <h1>{chord() || '*'}</h1>
        <h2>Goal: {goal}</h2>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <>
            {(notes as any)['0'] ? 'C' : '*'}
            {(notes as any)['1'] ? '1' : '*'}
            {(notes as any)['2'] ? 'D' : '*'}
            {(notes as any)['3'] ? '1' : '*'}
            {(notes as any)['4'] ? 'E' : '*'}
            {(notes as any)['5'] ? 'F' : '*'}
            {(notes as any)['6'] ? '1' : '*'}
            {(notes as any)['7'] ? 'G' : '*'}
            {(notes as any)['8'] ? '1' : '*'}
            {(notes as any)['9'] ? 'A' : '*'}
            {(notes as any)['10'] ? '1' : '*'}
            {(notes as any)['11'] ? 'B' : '*'}
          </>
        </a>
      </header>
    </div>
  );
}

export default App;
